// let directive = {
//     blur: function (el, binding) {
//         let options = {
//             blurred: true,
//             opacity: 0.5,
//             filter: 'blur(5px)',
//             transition: 'all .2s linear'
//         }
//         if (typeof binding.value !== 'boolean' && typeof binding.value !== 'object') {
//         throw new Error(
//             'Expected directive binding value type to be a boolean or an object but found ' +
//             `${typeof bindingValue} instead`
//         )
//         }
//         if (typeof binding.value == 'object') {
//             for (let option of Object.keys(binding.value)) {
//                 options[option] == binding.value[option]
//             }
//         }
//         el.style.opacity = binding.value ? options.opacity : 1
//         el.style.filter = binding.value ? options.filter : 'none'
//         el.style.transition = options.transition
//         el.style.pointerEvents = binding.value ? 'none' : 'auto';
//         el.style.userSelect = binding.value ? 'none' : 'auto' ;
//     },
//     beforeMount: function (el, binding) {
//         directive.blur(el, binding)
//     },
//     updated: function (el, binding) {
//         directive.blur(el, binding)
//     }
// }

// export default directive

export default function (el, binding) {
    let options = {
        blurred: true,
        opacity: 0.5,
        filter: 'blur(7.5px)',
        transition: 'all .2s linear'
    }
    if ((typeof binding.value !== 'boolean' && typeof binding.value !== 'object') & binding.value !== "") {
    throw new Error(
        'Expected directive binding value type to be a boolean or an object but found ' +
        `${typeof bindingValue} instead`
    )
    }
    if (typeof binding.value == 'object') {
        for (let option of Object.keys(binding.value)) {
            options[option] == binding.value[option]
        }
    }
    else if (typeof binding.value == 'boolean') {
        options.blurred == binding.value
    }
    el.style.opacity = binding.value ? options.opacity : 1
    el.style.filter = binding.value ? options.filter : 'none'
    el.style.transition = options.transition
    el.style.pointerEvents = binding.value ? 'none' : 'auto';
    el.style.userSelect = binding.value ? 'none' : 'auto' ;
}