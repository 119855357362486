import {
    createRouter,
    createWebHistory
} from "vue-router";

import {
    useCurrentUser, useFirebaseAuth
} from "vuefire";

import {
    signOut
} from "firebase/auth";
import * as Sentry from "@sentry/vue";

import useUserStore from '@/stores/user.js'

const routes = [{
        path: "/",
        name: "Dashboard",
        component: () => import("@/views/Dashboard/Dashboard.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Dashboard" }
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Auth/Login.vue"),
        meta: { requires_no_auth: true, title: "Login" }
    },
    {
        path: "/forgot",
        name: "Forgot",
        component: () => import("@/views/Auth/Forgot.vue"),
        meta: { requires_no_auth: true, title: "Forgot Password" }
    },
    // {
    //     path: "/register",
    //     name: "Register",
    //     component: () => import("@/views/Auth/Register.vue"),
    //     meta: { requires_no_auth: true, title: "Register" }
    // },
    {
        path: "/logout",
        name: "Logout",
        meta: { logout: true }
    },
    {
        path: "/override",
        name: "Override",
        component: () => import("@/views/Auth/Override.vue"),
        meta: { requires_no_auth: true, title: "Override" }
    },
    {
        path: "/shipments/",
        name: "Shipments",
        component: () => import("@/views/Shipments/Shipments.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Shipments" }
    },
    {
        path: "/shipments/inbound/",
        name: "ShipmentsInbound",
        component: () => import("@/views/Shipments/Inbound/Inbound.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Inbound Shipments" }
    },
    {
        path: "/shipments/inbound/create",
        name: "ShipmentsInboundCreate",
        component: () => import("@/views/Shipments/Inbound/Create.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Create Inbound Shipment" }
    },
    {
        path: "/shipments/inbound/:id",
        name: "ShipmentsInboundDetail",
        component: () => import("@/views/Shipments/Inbound/Detail.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Inbound Shipment" }
    },
    {
        path: "/shipments/outbound/",
        name: "ShipmentsOutbound",
        component: () => import("@/views/Shipments/Outbound/Outbound.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Outbound Shipments" }
    },
    {
        path: "/shipments/outbound/create",
        name: "ShipmentsOutboundCreate",
        component: () => import("@/views/Shipments/Outbound/Create.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Create Outbound Shipment" }
    },
    {
        path: "/shipments/outbound/:id",
        name: "ShipmentsOutboundDetail",
        component: () => import("@/views/Shipments/Outbound/Detail.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Outbound Shipment" }
    },
    {
        path: "/requests/",
        name: "Requests",
        component: () => import("@/views/Requests/Requests.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Requests" }
    },
    {
        path: "/requests/inspection",
        name: "RequestsInspection",
        component: () => import("@/views/Requests/Inspection.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Inspection Request" }
    },
    {
        path: "/requests/return",
        name: "RequestsReturn",
        component: () => import("@/views/Requests/Return.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Return Request" }
    },
    {
        path: "/requests/forward",
        name: "RequestsForward",
        component: () => import("@/views/Requests/Forward.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Forward Request" }
    },
    {
        path: "/requests/disposal",
        name: "RequestsDisposal",
        component: () => import("@/views/Requests/Disposal.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Disposal Request" }
    },
    {
        path: "/requests/:id",
        name: "RequestsDetail",
        component: () => import("@/views/Requests/Detail.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Request" }
    },
    {
        path: "/inventory",
        name: "Inventory",
        component: () => import("@/views/Inventory/Inventory.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Inventory" }
    },
    {
        path: "/inventory/create",
        name: "InventoryCreate",
        component: () => import("@/views/Inventory/Create.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Create Inventory Item" }
    },
    {
        path: "/inventory/:id",
        name: "InventoryDetail",
        component: () => import("@/views/Inventory/Detail.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Inventory Item" }
    },
    {
        path: "/billing/",
        name: "Billing",
        component: () => import("@/views/Billing/Billing.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Billing" }
    },
    {
        path: "/billing/invoices/",
        name: "BillingInvoices",
        component: () => import("@/views/Billing/Invoices/Invoices.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Invoices" }
    },
    {
        path: "/billing/invoices/:id",
        name: "BillingInvoicesDetail",
        component: () => import("@/views/Billing/Invoices/Invoice.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Invoice" }
    },
    {
        path: "/settings/",
        name: "Settings",
        component: () => import("@/views/Settings/Settings.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Settings" }
    },
    {
        path: "/settings/profile",
        name: "SettingsProfile",
        component: () => import("@/views/Settings/Profile.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Profile" }
    },
    {
        path: "/settings/notifications",
        name: "SettingsNotifications",
        component: () => import("@/views/Settings/Notifications.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Notifications" }
    },
    {
        path: "/settings/account",
        name: "SettingsAccount",
        component: () => import("@/views/Settings/Account.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Account" }
    },
    {
        path: "/settings/integrations",
        name: "SettingsIntegrations",
        component: () => import("@/views/Settings/Integrations.vue"),
        meta: { requires_auth: true, requires_premium: true, title: "Integrations" }
    },
    {
        path: "/onboarding",
        name: "OnboardingDefault",
        // component: () => import("@/views/Onboarding/Account.vue"),
        meta: { redirect: "OnboardingAccount" }
    },
    {
        path: "/onboarding/step1",
        name: "OnboardingAccount",
        component: () => import("@/views/Onboarding/Account.vue"),
        meta: { requires_no_auth: true, redirect_if_auth: "OnboardingDiscord", title: "Onboarding Step 1" }
    },
    {
        path: "/onboarding/step2",
        name: "OnboardingDiscord",
        component: () => import("@/views/Onboarding/Discord.vue"),
        meta: { requires_auth: true, redirect_if_no_auth: "OnboardingAccount", title: "Onboarding Step 2" }
    },
    {
        path: "/onboarding/step3",
        name: "OnboardingSurvey",
        component: () => import("@/views/Onboarding/Survey.vue"),
        meta: { requires_auth: true, redirect_if_no_auth: "OnboardingAccount", title: "Onboarding Step 3" }
    },
    {
        path: "/onboarding/step4",
        name: "OnboardingPayment",
        component: () => import("@/views/Onboarding/Payment.vue"),
        meta: { requires_auth: true, redirect_if_no_auth: "OnboardingAccount", title: "Onboarding Step 4" }
    },
    {
        path: "/onboarding/step5",
        name: "OnboardingSuccess",
        component: () => import("@/views/Onboarding/Success.vue"),
        meta: { requires_auth: true, redirect_if_no_auth: "OnboardingAccount", title: "Onboarding Step 5" }
    },
    {
        path: "/error/400",
        name: "400",
        component: () => import("@/views/Error/400.vue"),
        meta: { auth_no_preference: true }
    },
    {
        path: "/error/500",
        name: "500",
        component: () => import("@/views/Error/500.vue"),
        meta: { auth_no_preference: true }
    },
    {
        path: "/error/503",
        name: "503",
        component: () => import("@/views/Error/503.vue"),
        meta: { auth_no_preference: true }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("@/views/Error/404.vue"),
        meta: { auth_no_preference: true }
    }
    // {
    //     path: "/",
    //     name: "",
    //     component: () => import("@/views/")
    // },
]

const router = createRouter({
    history: createWebHistory(
        import.meta.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore()
    document.title = "PrepWorx - " + to.meta.title || "PrepWorx";

    // if (currentUser) {
    //     Sentry.setUser({ email: isAuth.email, username: isAuth.uid });
    // } else {
    //     Sentry.setUser(null);
    // }

    const requires_auth = to.meta.requires_auth || false;
    const auth_no_preference = to.meta.auth_no_preference || false;
    const requires_premium = to.meta.requires_premium || false;
    const requires_no_auth = to.meta.requires_no_auth || false;
    const redirect_if_auth = to.meta.redirect_if_auth || "";
    const redirect_if_no_auth = to.meta.redirect_if_no_auth || "";
    const logout = to.meta.logout || false;
    const redirect = to.meta.redirect || false;
    const redirect_url = to.meta.redirect_url || false;
    if (requires_premium && userStore.stateData.isLoggedIn && (!userStore.stateData.claims.hasOwnProperty("stripeRole") || (userStore.stateData.claims.stripeRole || "") != "premium")) {
        next({ name: "OnboardingDiscord" })
    }
    if (logout) {
        if (userStore.stateData.isLoggedIn) {
            Sentry.setUser(null);
            OpenReplay.setUserID("")
            userStore.clear() 
            signOut(useFirebaseAuth())
        }
        window.location = router.resolve({
            name: "Login"
        }).href
        // next({ name: "Login" })
    }
    else if (requires_auth && !userStore.stateData.isLoggedIn) {
        next({ name: "Login" })
    }
    else if (requires_no_auth && redirect_if_auth != "" && userStore.stateData.isLoggedIn) {
        next({ name: redirect_if_auth })
    }
    else if (requires_auth && redirect_if_no_auth != "" && !userStore.stateData.isLoggedIn) {
        next({ name: redirect_if_no_auth })
    }
    else if (requires_no_auth && userStore.stateData.isLoggedIn) {
        next({ name: "Dashboard" })
    }
    else if (redirect) {
        next({ name: redirect })
    }
    else if (redirect_url) {
        window.location = redirect_url
    }
    else {
        next()
    }
})

router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
        window.location = to.fullPath
    }
})

export default router;