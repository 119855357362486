import { defineStore } from 'pinia';
import { reactive, watch, ref } from 'vue';
import {
    useCollection,
    useDocument,
    useFirestore
} from 'vuefire'
import {
    collection,
    query,
    where,
    doc,
    orderBy,
    limit
} from 'firebase/firestore'

const storeName = 'db'

export default defineStore(storeName, () => {
    const stateData = reactive({
        initialized: false,
        completed: false,
        inventory: [],
        inventoryPending: true,
        inboundShipments: [],
        inboundShipmentsPending: true,
        outboundShipments: [],
        outboundShipmentsPending: true,
        requests: [],
        requestsPending: true,
        events: [],
        eventsPending: true,
        user: {},
        userPending: true,
        platform: {},
        platformPending: true
    })
    
    const initialize = async (uid) => {
        const db = useFirestore();
        stateData.initialized = true;
        const {
            data: inventory,
            pending: inventoryPending
        } = useCollection(query(collection(db, 'inventory'), where('owner', '==', uid)))
        stateData.inventory = inventory
        stateData.inventoryPending = inventoryPending
        const {
            data: inboundShipments,
            pending: inboundShipmentsPending
        } = useCollection(query(collection(db, 'inbound_shipments'), where('owner', '==', uid)))
        stateData.inboundShipments = inboundShipments
        stateData.inboundShipmentsPending = inboundShipmentsPending
        const {
            data: outboundShipments,
            pending: outboundShipmentsPending
        } = useCollection(query(collection(db, 'outbound_shipments'), where('owner', '==', uid)))
        stateData.outboundShipments = outboundShipments
        stateData.outboundShipmentsPending = outboundShipmentsPending
        const {
            data: requests,
            pending: requestsPending
        } = useCollection(query(collection(db, 'requests'), where('owner', '==', uid)))
        stateData.requests = requests
        stateData.requestsPending = requestsPending
        const {
            data: events,
            pending: eventsPending
        } = useCollection(query(collection(db, 'events'), where('owner', '==', uid), orderBy('created'), limit(10)))
        stateData.events = events
        stateData.eventsPending = eventsPending
        const {
            data: user,
            pending: userPending
        } = useDocument(doc(db, 'users', uid))
        stateData.user = user
        stateData.userPending = userPending
        const {
            data: platform,
            pending: platformPending
        } = useDocument(doc(db, 'platform', 'external'))
        stateData.platform = platform
        stateData.platformPending = platformPending
    }

    watch(() => stateData, (state) => {
        if (!state.inventoryPending && !state.inboundShipmentsPending && !state.outboundShipmentsPending && !state.requestsPending && !state.eventsPending && !state.userPending && !state.platformPending) {
            stateData.completed = true;
        }
    }, { deep: true })

    return {
        stateData,
        initialize
    }
})