import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from "./router/index";
import App from './App.vue'

import { VueFire, VueFireAuth } from 'vuefire'
import { firebaseApp } from './firebase'

import { plugin, defaultConfig } from '@formkit/vue'

import naive from 'naive-ui'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import Blur from "./helpers/blur.js";

import './style.css'

import LoadScript from "vue-plugin-load-script";

import * as Sentry from "@sentry/vue";

// import useUserStore from '@/stores/user.js'

const app = createApp(App)

const pinia = createPinia()

app.use(pinia)

app.use(router)

Sentry.init({
    app,
    dsn: "https://b384bd5ab4f342be855c2d24ccdd2b96@o1420712.ingest.sentry.io/4505144881512448",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        // tracingOrigins: ["localhost", "beta.prepworx.io", "app.prepworx.io", /^\//]
      }),
      new Sentry.Replay({
        sessionSampleRate: 0.1,
        errorSampleRate: 1.0
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // beforeSend(event, hint) {
    //     const ignored_exceptions = ["ResizeObserver loop completed with undelivered notifications."]
    //     // console.log(event)
    // // Check if it is an exception, and if so, show the report dialog
    //     if (event.exception && !ignored_exceptions.includes(event.exception.values[0].value)) {
    //         const userStore = useUserStore()
    //         Sentry.showReportDialog({
    //             eventId: event.event_id,
    //             user: {
    //                 name: userStore.stateData.uid,
    //                 email: userStore.stateData.email
    //             },
    //             title: "It looks like the action you tried to perform ran into an issue.",
    //             labelName: "Client ID"
    //         });
    //     }
    //     return event;
    // }
});

app.use(LoadScript)

app.use(VueFire, {
    firebaseApp,
    modules: [
        VueFireAuth(),
    ]
})

app.use(naive)

app.use(Toast, {
    transition: "my-custom-fade",
    maxToasts: 10,
    newestOnTop: true,
    position: "bottom-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.5,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    rtl: false
})

app.use(plugin, defaultConfig)

app.directive('blur', Blur)

app.mount('#app')